import React, { useMemo, useState } from "react";

import { Refresh } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

interface BankIdThisDeviceLoginProps {
  bankIdOpened: boolean;
  loginFailed: boolean;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  startBankIdLogin: () => void;
  refetchStatus: () => void;
  userMessage: string;
}

type State = "not_started" | "working" | "timeout";
const primaryButtonTexts: Record<State, string> = {
  not_started: "IDENTIFIERA DIG",
  working: "VÄNTAR PÅ APPLIKATION",
  timeout: "VÄNTAR PÅ APPLIKATION",
};

const BankIdThisDeviceLogin: React.FC<BankIdThisDeviceLoginProps> = ({
  loginFailed,
  onBackClick,
  startBankIdLogin,
  refetchStatus,
  userMessage,
}: BankIdThisDeviceLoginProps) => {
  const [currentState, setCurrentState] = useState<State>("not_started");

  const disabled = useMemo(() => currentState === "working", [currentState]);

  const onOpenBankIdClick = async (
    _event?: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (currentState === "timeout") {
      refetchStatus();
      return;
    }
    setCurrentState("working");
    await startBankIdLogin();
    setTimeout(() => setCurrentState("timeout"), 10 * 1000);
  };

  return (
    <>
      <Box
        component="img"
        src="/images/BankID_logo.svg"
        alt="BankID Logo"
        sx={styles.imageLogo}
      />
      {!loginFailed && (
        <Button
          variant="contained"
          fullWidth
          onClick={onOpenBankIdClick}
          disabled={disabled}
          disableRipple
          sx={styles.primaryButton}
          endIcon={currentState == "timeout" ? <Refresh /> : undefined}
        >
          {primaryButtonTexts[currentState]}
        </Button>
      )}
      {loginFailed && (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {userMessage}
        </Typography>
      )}
      <Button
        fullWidth
        disableRipple
        variant="text"
        color="secondary"
        sx={styles.secondaryButton}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

const styles = {
  imageLogo: {
    width: 140,
    height: 140,
    ml: "auto !important", // for some reason, Stack's margin will overwrite these
    mr: "auto !important",
    mt: "0 !important",
    mb: "0 !important",
    display: "block",
  },
  primaryButton: {
    margin: "16px auto 0 auto !important",
    mt: 0.5,
    borderRadius: 6,
    height: 48,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "16px",
    letterSpacing: 1,
    textTransform: "uppercase",
    "&:disabled": {
      backgroundColor: "rgba(13, 48, 80, 0.5)",
      color: "#FFFFFF",
      boxShadow: "unset",
    },
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "secondary.main",
    },
    "&:active": {
      backgroundColor: "#275284",
    },
  },
  secondaryButton: {
    display: "inline-flex",
    margin: "16px auto 0 auto !important",
    mt: 0.5,
    borderRadius: 6,
    textDecoration: "underline",
    height: 48,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "16px",
    letterSpacing: 1,
    textUnderlineOffset: 4,
    color: "primary.main",
    "&:hover": {
      textDecoration: "underline",
      color: "#FFFFFF",
      backgroundColor: "secondary.main",
    },
    "&:active": {
      backgroundColor: "#275284",
      textDecoration: "underline",
      boxShadow: "unset",
    },
  },
};

export default BankIdThisDeviceLogin;

import React, { FC, useCallback, useEffect, useMemo } from "react";

import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import BankIdMobileLoginQr from "./BankIdMobileLoginQr";
import BankIdThisDeviceLogin from "./BankIdThisDeviceLogin";
import { loginTitleStyle, mainCardStyle } from "./Styles";
import { LoginMethod, useBankId } from "./shared";
import { ToggleMode } from "./shared/components/ToggleMode";
import { useBankIdFlow } from "./shared/hooks/useBankIdFlow";
import { hintCodeMessage, resolveMessage } from "./texts";
import { useEnv } from "./shared/provider/EnvironmentProvider";
import {
  getBankIdUrlMobile,
  getBankIdUrlOther,
  getQueryParam,
} from "./shared/utils";
import { useLocalStore } from "./shared/hooks/useLocalStore";

interface BankIdFrameProps {
  sx?: SxProps<Theme>;
}

const BankIdFrame: React.FC<BankIdFrameProps> = ({ sx = [] }) => {
  // const [debug, _] = useLocalStore("debug", getQueryParam("debug"));
  const { ready, env } = useEnv();
  const { loginMethod, setLoginMethod } = useBankId()!;
  const {
    mode,
    startFlow,
    isQR,
    qrData,
    status,
    isBankIdAppOpen,
    autoStartToken,
    refetchStatus
  } = useBankIdFlow();

  const navBack = useCallback(() => {
    if (!ready) {
      window.history.back();
      return;
    }
    const { back_url } = env;
    if (!back_url) {
      window.history.back();
      return;
    }
    window.location.assign(back_url);
  }, [ready]);

  const userMessage = useMemo(() => {
    if (!status) return "";
    if (status.status == "failed")
      return resolveMessage(hintCodeMessage, status.hintCode);
    return "";
  }, [status?.hintCode]);

  const timeoutMessage =
    "Tiden för att läsa av QR-koden har gått ut. Försök att logga in igen. Om det inte funkar prova att uppdatera BankID-appen på din telefon.";

  const clearCache = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("Cache Cleared");
  };

  return (
    <Container sx={{ minHeight: "calc(100vh - 450px)" }}>
      <Card variant="elevation" sx={mainCardStyle} elevation={0}>
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
          <Stack spacing={3}>
            <Typography sx={loginTitleStyle}>Logga in</Typography>
            <ToggleMode
              loginMethod={loginMethod}
              onChange={(_, nv) => {
                setLoginMethod(nv as LoginMethod);
                if (loginMethod == "this_device") startFlow();
              }}
            />
            {isQR ? (
              <BankIdMobileLoginQr
                onBackClick={() => Promise.resolve(navBack())}
                loginFailed={mode === "failed"}
                qrCode={qrData?.qrCode || ""}
                userMessage={timeoutMessage}
                logInAgain={() => Promise.resolve(navBack())}
              />
            ) : (
              <BankIdThisDeviceLogin
                bankIdOpened={isBankIdAppOpen}
                loginFailed={mode === "failed"}
                startBankIdLogin={startFlow}
                onBackClick={() => Promise.resolve(navBack())}
                userMessage={userMessage}
                refetchStatus={refetchStatus}
              />
            )}
            {/* {debug === "true" && <DebugSettings autoStartToken={autoStartToken} />} */}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

const openLink = (url: string) => window.location.assign(url);
const DebugSettings: FC<{ autoStartToken?: string }> = ({ autoStartToken }) => {
  if (!autoStartToken) return <div>DEBUG: DISABLED!</div>;
  // if (!autoStartToken) return <div>DEBUG: you have to start the flow!</div>;
  return (
    <>
      <div>{autoStartToken}</div>
      <Stack direction="row" spacing={1}>
        <Stack>
          <div>bankid:///...</div>
          <Button
            onClick={() => {
              openLink(getBankIdUrlOther(autoStartToken, null));
            }}
          >
            none
          </Button>
          <Button
            onClick={() => {
              openLink(getBankIdUrlOther(autoStartToken, "null"));
            }}
          >
            null
          </Button>
          <Button
            onClick={() => {
              openLink(getBankIdUrlOther(autoStartToken, window.location.href));
            }}
          >
            curr
          </Button>
        </Stack>
        <Stack>
          <div>https://app.bankid.com/...</div>
          <Button
            onClick={() => {
              openLink(getBankIdUrlMobile(autoStartToken, null));
            }}
          >
            none
          </Button>
          <Button
            onClick={() => {
              openLink(getBankIdUrlMobile(autoStartToken, "null"));
            }}
          >
            null
          </Button>
          <Button
            onClick={() => {
              openLink(
                getBankIdUrlMobile(autoStartToken, window.location.href)
              );
            }}
          >
            curr
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default BankIdFrame;
